import { render, staticRenderFns } from "./Resizer.vue?vue&type=template&id=4d0ec7c7&scoped=true"
import script from "./Resizer.vue?vue&type=script&lang=js"
export * from "./Resizer.vue?vue&type=script&lang=js"
import style0 from "./Resizer.vue?vue&type=style&index=0&id=4d0ec7c7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__vue-te_eoxntyycrqiuiceqfmr2hlaiyy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d0ec7c7",
  null
  
)

export default component.exports