<template>
	<!--Transition :name="overlayTransition"-->
	<div
		v-if="visibility.overlay"
		ref="overlay"
		:class="overlayClass"
		:aria-expanded="visibility.overlay.toString()"
		:data-modal="name"
	>
		<div
			class="v--modal-background-click w-full"
			:class="scrollable ? 'h-max-content' : 'h-full'"
			@click.self="handleBackgroundClick"
		>
			<div class="v--modal-top-right">
				<slot name="top-right" />
			</div>
			<Transition
				:name="transition"
				@before-enter="beforeTransitionEnter"
				@after-enter="afterTransitionEnter"
				@after-leave="afterTransitionLeave"
			>
				<div v-if="visibility.modal" ref="modal" :class="modalClass" :style="modalStyle">
					<template v-if="showCloseButton">
						<span
							data-cy="closeModal"
							class="cursor-pointer absolute top-0 right-0"
							@click="handleCloseButton"
						>
							<HokIcon icon="icon-close-slim" :size="4" color="text" pointer class="m-3" />
						</span>
					</template>
					<slot />

					<Resizer
						v-if="resizable && !isAutoHeight"
						:min-width="minWidth"
						:min-height="minHeight"
						@resize="handleModalResize"
					/>
				</div>
			</Transition>
		</div>
	</div>
	<!--/Transition-->
</template>
<script lang="js">
import Resizer from './Resizer.vue';
import { createModalEvent, getMutationObserver, inRange } from './util';
import { parseNumber, validateNumber } from './parser';
// eslint-disable-next-line import/no-cycle
import Modal from './modalPlugin';

export default {
	name: 'VueJsModal',
	components: {
		Resizer
	},
	props: {
		name: {
			required: true,
			type: String
		},
		lazy: {
			required: false,
			type: Number,
			default: 0
		},
		delay: {
			type: Number,
			default: 0
		},
		resizable: {
			type: Boolean,
			default: false
		},
		adaptive: {
			type: Boolean,
			default: false
		},
		draggable: {
			type: [Boolean, String],
			default: false
		},
		scrollable: {
			type: Boolean,
			default: false
		},
		reset: {
			type: Boolean,
			default: false
		},
		showCloseButton: {
			type: Boolean,
			default: true
		},
		overlayTransition: {
			type: String,
			default: 'overlay-fade'
		},
		transition: {
			type: String,
			default: ''
		},
		clickToClose: {
			type: Boolean,
			default: false
		},
		classes: {
			type: [String, Array],
			default: 'v--modal'
		},
		minWidth: {
			type: Number,
			default: 0,
			validator(value) {
				return value >= 0;
			}
		},
		minHeight: {
			type: Number,
			default: 0,
			validator(value) {
				return value >= 0;
			}
		},
		maxWidth: {
			type: Number,
			default: Infinity
		},
		maxHeight: {
			type: Number,
			default: Infinity
		},
		width: {
			type: [Number, String],
			default: '95%', // this.$isMobile.phone ? '95%' : '500px',
			validator: validateNumber
		},
		height: {
			type: [Number, String],
			default: 'auto',
			validator(value) {
				return value === 'auto' || validateNumber(value);
			}
		},
		pivotX: {
			type: Number,
			default: 0.5,
			validator(value) {
				return value >= 0 && value <= 1;
			}
		},
		pivotY: {
			type: Number,
			default: 0.5,
			validator(value) {
				return value >= 0 && value <= 1;
			}
		},
		// choose in which order to display modals, use tailwind class to prevent post-css
		zIndex: {
			type: String,
			default: 'z-[200]'
		}
	},
	data() {
		return {
			visible: false,

			visibility: {
				modal: false,
				overlay: false
			},

			shift: {
				left: 0,
				top: 0
			},

			modal: {
				width: 0,
				widthType: 'px',
				height: 0,
				heightType: 'px',
				renderedHeight: 0
			},

			window: {
				width: 0,
				height: 0
			},

			mutationObserver: null
		};
	},
	computed: {
		/**
		 * Returns true if height is set to "auto"
		 */
		isAutoHeight() {
			return this.modal.heightType === 'auto';
		},
		/**
		 * Calculates and returns modal position based on the pivots, window size and modal size
		 */
		position() {
			const { window, shift, pivotX, pivotY, trueModalWidth, trueModalHeight } = this;

			// console.log("POSITION RECALC", shift, pivotX, pivotY, trueModalHeight, trueModalWidth);

			const maxLeft = (window.width || window.innerWidth) - trueModalWidth;
			const maxTop = (window.height || window.innerHeight) - trueModalHeight;

			// console.log("maxTop",maxTop, window.height, trueModalHeight);

			const left = shift.left + pivotX * maxLeft;
			const top = shift.top + pivotY * maxTop;

			return {
				left: parseInt(inRange(0, maxLeft, left), 10),
				top: parseInt(inRange(10, maxTop, top), 10)
			};
		},
		/**
		 * Returns pixel width (if set with %) and makes sure that modal size fits the window
		 */
		trueModalWidth() {
			const { window, modal, adaptive, minWidth, maxWidth } = this;

			const value = modal.widthType === '%' ? (window.width / 100) * modal.width : modal.width;

			const max = Math.min(window.width, maxWidth);

			return adaptive ? inRange(minWidth, max, value) : value;
		},
		/**
		 * Returns pixel height (if set with %) and makes sure that modal size fits the window.
		 *
		 * Returns modal.renderedHeight if height set as "auto"
		 */
		trueModalHeight() {
			const { window, modal, isAutoHeight, adaptive, maxHeight } = this;

			// console.log("trueModalHeight!");

			const value = modal.heightType === '%' ? (window.height / 100) * modal.height : modal.height;

			if (isAutoHeight) {
				// use renderedHeight when height 'auto'
				return this.modal.renderedHeight;
			}

			const max = Math.min(window.height - 30, maxHeight);

			return adaptive ? inRange(this.minHeight, max, value) : value;
		},
		/**
		 * Returns class list for screen overlay (modal background)
		 */
		overlayClass() {
			return [
				{
					'v--modal-overlay': true,
					scrollable: this.scrollable && this.isAutoHeight
				},
				this.zIndex
			];
		},
		/**
		 * Returns class list for modal itself
		 */
		modalClass() {
			return ['v--modal-box', this.classes];
		},
		/**
		 * CSS styles for position and size of the modal
		 */
		modalStyle() {
			return {
				top: `${this.position.top}px`,
				left: `${this.position.left}px`,
				width: `${this.trueModalWidth}px`,
				height: this.isAutoHeight ? 'auto' : `${this.trueModalHeight}px`
			};
		}
	},
	created() {
		this.setInitialSize();
	},
	/**
	 * Sets global listeners
	 */
	beforeMount() {
		Modal.event.$on('toggle', this.handleToggleEvent);

		window.addEventListener('resize', this.handleWindowResize);
		this.handleWindowResize();
		/**
		 * Making sure that autoHeight is enabled when using "scrollable"
		 */
		if (this.scrollable && !this.isAutoHeight) {
			console.warn(
				`Modal "${this.name}" has scrollable flag set to true ` +
					`but height is not "auto" (${this.height})`
			);
		}
		/**
		 * Only observe when using height: 'auto' The callback will be called when modal DOM changes,
		 * this is for updating the `top` attribute for height 'auto' modals.
		 */
		// console.log("this.isAutoHeight)",this.isAutoHeight);

		if (this.isAutoHeight) {
			/**
			 * MutationObserver feature detection:
			 *
			 * Detects if MutationObserver is available, return false if not. No polyfill is provided
			 * here, so height 'auto' recalculation will simply stay at its initial height (won't crash).
			 * (Provide polyfill to support IE < 11)
			 *
			 * https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
			 *
			 * For the sake of SSR, MutationObserver cannot be initialized before component creation >_<
			 */
			const MutationObserver = getMutationObserver();

			if (MutationObserver) {
				// console.log("MutationObserver!");
				this.mutationObserver = new MutationObserver(() => {
					this.updateRenderedHeight();
				});
			} else {
				// console.log("APPLE?");
			}
		}

		if (this.clickToClose) {
			window.addEventListener('keyup', this.handleEscapeKeyUp);
		}
	},
	/**
	 * Removes global listeners
	 */
	beforeDestroy() {
		Modal.event.$off('toggle', this.handleToggleEvent);
		window.removeEventListener('resize', this.handleWindowResize);

		if (this.clickToClose) {
			window.removeEventListener('keyup', this.handleEscapeKeyUp);
		}
		/**
		 * Removes blocked scroll
		 */
		if (this.scrollable) {
			document.body.classList.remove('v--modal-block-scroll');
		}
	},
	mounted() {
		if (this.width < 0) {
			// eslint-disable-next-line vue/no-mutating-props
			this.width = this.$isMobile.phone ? '95%' : '500px';
		}
	},
	methods: {
		handleToggleEvent(name, state, params) {
			if (this.name === name) {
				const nextState = typeof state === 'undefined' ? !this.visible : state;

				this.toggle(nextState, params);
			}
		},
		/**
		 * Initializes modal's size & position, if "reset" flag is set to true - this function will be
		 * called every time "beforeOpen" is triggered
		 */
		setInitialSize() {
			const { modal } = this;

			const width = parseNumber(
				this.width.toString().includes('95%') && !this.$isMobile.phone ? '500px' : this.width
			);
			const height = parseNumber(this.height);

			modal.width = width.value;
			modal.widthType = width.type;
			modal.height = height.value;
			modal.heightType = height.type;
		},

		handleEscapeKeyUp(event) {
			if (event.which === 27 && this.visible) {
				this.$modal.hide(this.name);
			}
		},

		handleWindowResize() {
			this.window.width = window.innerWidth;
			this.window.height = window.innerHeight;
		},
		/**
		 * Generates event object
		 */
		createModalEvent(args = {}) {
			return createModalEvent({
				name: this.name,
				ref: this.$refs.modal,
				...args
			});
		},
		/**
		 * Triggered when modal is closed via close-button
		 */
		handleCloseButton() {
			this.$modal.hide(this.name);
			this.$emit('close-button');
		},
		/**
		 * Event handler which is triggered on modal resize
		 */
		handleModalResize(event) {
			this.modal.widthType = 'px';
			this.modal.width = event.size.width;

			this.modal.heightType = 'px';
			this.modal.height = event.size.height;

			const { size } = this.modal;

			this.$emit('resize', this.createModalEvent({ size }));
		},
		/**
		 * Event handler which is triggered on $modal.show and $modal.hide
		 *
		 * BeforeEvents: ('before-close' and 'before-open') are `$emit`ed here,\
		 * but AfterEvents ('opened' and 'closed') are moved to `watch.visible`.
		 */
		toggle(nextState, params) {
			const { reset, visible } = this;

			if (visible === nextState) {
				return;
			}

			const beforeEventName = visible ? 'before-close' : 'before-open';

			if (beforeEventName === 'before-open') {
				/**
				 * Need to unfocus previously focused element, otherwise all keypress events (ESC press, for
				 * example) will trigger on that element.
				 */
				if (
					document.activeElement &&
					document.activeElement.tagName !== 'BODY' &&
					document.activeElement.blur
				) {
					document.activeElement.blur();
				}

				if (reset) {
					this.setInitialSize();

					this.shift.left = 0;
					this.shift.top = 0;
				}

				// prevent page scrolling while modal is open and pass on scrollbar width for body to prevent layout shift
				document.body.style.setProperty(
					'--scrollbar-width',
					`${window.innerWidth - document.documentElement.clientWidth}px`
				);
				document.body.classList.add('v--modal-block-scroll');
			} else if (beforeEventName === 'before-close') {
				document.body.classList.remove('v--modal-block-scroll');
			}

			let stopEventExecution = false;

			const stop = () => {
				stopEventExecution = true;
			};

			const beforeEvent = this.createModalEvent({
				stop,
				state: nextState,
				params
			});

			this.$emit(beforeEventName, beforeEvent);

			if (!stopEventExecution) {
				this.visible = nextState;
				if (this.visible) {
					this.startOpeningModal();
				} else {
					this.startClosingModal();
				}
			}
		},

		getDraggableElement() {
			const selector = typeof this.draggable !== 'string' ? '.v--modal-box' : this.draggable;

			return selector ? this.$refs.overlay.querySelector(selector) : null;
		},
		/**
		 * Event handler that is triggered when background overlay is clicked
		 */
		handleBackgroundClick() {
			if (this.clickToClose) {
				this.toggle(false);
				this.$emit('click-closed');
			}
		},

		startOpeningModal() {
			/*	if (this.$isMobile.any) {
        document.body.style.top = `-${window.scrollY}px`;
        document.body.style.position = 'fixed';
        document.body.style.pointerEvents = 'none';
        document.body.style.width = '100%';
      } */
			this.visibility.overlay = this.lazy === 0;
			setTimeout(() => {
				if (this.lazy > 0) {
					const openModal = this.checkForOtherModal();
					this.visibility.overlay = !openModal;
				}
				this.visibility.modal = true;

				if (this.isAutoHeight) {
					this.$nextTick(() => {
						this.updateRenderedHeight();
					});
				}
			}, this.lazy * 1000);
		},

		checkForOtherModal() {
			const openModal = document.querySelector('.v--modal');
			return !!openModal;
		},

		startClosingModal() {
			/*		if (this.$isMobile.any) {
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.pointerEvents = '';
        document.body.style.top = '';
        document.body.style.width = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      } */
			this.visibility.modal = false;
			setTimeout(() => {
				this.visibility.overlay = false;
			}, this.delay);
		},

		addDraggableListeners() {
			if (!this.draggable) {
				return;
			}

			const dragger = this.getDraggableElement();

			if (dragger) {
				let startX = 0;
				let startY = 0;
				let cachedShiftX = 0;
				let cachedShiftY = 0;

				const getPosition = event =>
					event.touches && event.touches.length > 0 ? event.touches[0] : event;

				const handleDraggableMousemove = event => {
					const { clientX, clientY } = getPosition(event);

					this.shift.left = cachedShiftX + clientX - startX;
					this.shift.top = cachedShiftY + clientY - startY;

					event.preventDefault();
				};

				const handleDraggableMouseup = event => {
					document.removeEventListener('mousemove', handleDraggableMousemove);
					document.removeEventListener('touchmove', handleDraggableMousemove);

					document.removeEventListener('mouseup', handleDraggableMouseup);
					document.removeEventListener('touchend', handleDraggableMouseup);

					event.preventDefault();
				};

				const handleDraggableMousedown = event => {
					const { target } = event;

					if (target && target.nodeName === 'INPUT') {
						return;
					}

					const { clientX, clientY } = getPosition(event);

					document.addEventListener('mousemove', handleDraggableMousemove);
					document.addEventListener('touchmove', handleDraggableMousemove);

					document.addEventListener('mouseup', handleDraggableMouseup);
					document.addEventListener('touchend', handleDraggableMouseup);

					startX = clientX;
					startY = clientY;

					cachedShiftX = this.shift.left;
					cachedShiftY = this.shift.top;
				};

				dragger.addEventListener('mousedown', handleDraggableMousedown);
				dragger.addEventListener('touchstart', handleDraggableMousedown);
			}
		},

		removeDraggableListeners() {
			/**
			 * Ideally this is not needed because "dragger" will be unmounted anyway.
			 */
		},
		/**
		 * Update $data.modal.renderedHeight using getBoundingClientRect. This method is called when:
		 *
		 * 1. modal opened
		 * 2. MutationObserver's observe callback
		 */
		updateRenderedHeight() {
			if (this.$refs.modal) {
				// console.log("updated rendered hight 2", this.$refs.modal.getBoundingClientRect());
				this.modal.renderedHeight = this.$refs.modal.getBoundingClientRect().height;
			}
		},
		/**
		 * Start observing modal's DOM, if childList or subtree changes, the callback (registered in
		 * beforeMount) will be called.
		 */
		connectObserver() {
			if (this.mutationObserver) {
				this.mutationObserver.observe(this.$refs.overlay, {
					childList: true,
					attributes: true,
					subtree: true
				});
			}
		},
		/**
		 * Disconnects MutationObserver
		 */
		disconnectObserver() {
			if (this.mutationObserver) {
				this.mutationObserver.disconnect();
			}
		},

		beforeTransitionEnter() {
			this.connectObserver();
		},

		afterTransitionEnter() {
			this.addDraggableListeners();

			this.$emit('opened', this.createModalEvent({ state: true }));
		},

		afterTransitionLeave() {
			this.removeDraggableListeners();
			this.disconnectObserver();
			this.$emit('closed', this.createModalEvent({ state: false }));
		}
	}
};
</script>
<style lang="scss" scoped>
.v--modal-overlay {
	overflow-y: scroll;
	position: fixed;
	box-sizing: border-box;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	background: rgba(0, 0, 0, 0.2);
}

.v--modal-overlay.scrollable {
	height: 100%;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.v--modal-overlay .v--modal-box {
	position: relative;
	overflow: visible;
	box-sizing: border-box;
	pointer-events: all;
}

.v--modal-overlay.scrollable .v--modal-box {
	margin-bottom: 30px;
}

.v--modal {
	background-color: white;
	text-align: left;
	border-radius: 0.375rem; // rounded-md
	box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);
	padding: $s8;
	position: relative;

	&.noPadding {
		padding: 0;
	}

	&.whiteCloseIcon {
		.icon-close-slim {
			color: white;
		}
	}

	::v-deep(button.float-right) {
		// floated buttons are not included in height calculation of modals, therefore reset it
		float: none;
	}
}

.v--modal.v--modal-fullscreen {
	width: 100vw;
	height: 100vh;
	margin: 0;
	left: 0;
	top: 0;
}

.v--modal-top-right {
	display: block;
	position: absolute;
	right: 0;
	top: 0;
}

.scale-enter-active,
.scale-leave-active {
	transition: all 300ms;
}

.scale-enter,
.scale-leave-active {
	opacity: 0;
	transform: scale(0.3) translateY(24px);
}

.overlay-fade-enter-active,
.overlay-fade-leave-active {
	transition: all 0.2s;
}

.overlay-fade-enter,
.overlay-fade-leave-active {
	opacity: 0;
}

.nice-modal-fade-enter-active,
.nice-modal-fade-leave-active {
	transition: all 0.4s;
}

.nice-modal-fade-enter,
.nice-modal-fade-leave-active {
	opacity: 0;
	transform: translateY(-20px);
}
</style>
